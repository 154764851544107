import React from "react"

import heroImage from "../images/undraw_designer_life_w96d.svg"

export default function Home() {
  return (
    <div className="hero">
      <div className="hero-text">
        <h1>Looking for a pro in Gatsbyjs?</h1>
        <h2>
          We design super fast website using Gatsbyjs and give your business a
          boost!
        </h2>
        <button>See how?</button>
      </div>
      <div className="hero-image">
        <img src={heroImage} alt="Gatsby Website Development" />
      </div>
    </div>
  )
}
